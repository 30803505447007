// extracted by mini-css-extract-plugin
export var root = "ProgramBenefitsBlock-module--root--e0Oyt";
export var leftSide = "ProgramBenefitsBlock-module--left-side--1aazu";
export var rightSide = "ProgramBenefitsBlock-module--right-side--1VITb";
export var wphfStyle = "ProgramBenefitsBlock-module--wphf-style--hmm79";
export var title = "ProgramBenefitsBlock-module--title--R0ws2";
export var content = "ProgramBenefitsBlock-module--content--39E9U";
export var benefitTitle = "ProgramBenefitsBlock-module--benefit-title--1-nnJ";
export var numCircle = "ProgramBenefitsBlock-module--num-circle--2zp-0";
export var mystrengthStyle = "ProgramBenefitsBlock-module--mystrength-style--1iC8c";
export var bulletPointContainer = "ProgramBenefitsBlock-module--bullet-point-container--3sNTV";
export var programBenefitsBackgroundBlock = "ProgramBenefitsBlock-module--program-benefits-background-block---f_WS";
export var image = "ProgramBenefitsBlock-module--image--BHfs_";
export var bulletPointUL = "ProgramBenefitsBlock-module--bullet-point-UL--2oeN6";
export var bulletPoint = "ProgramBenefitsBlock-module--bullet-point--1-VGh";
export var blueCheckbox = "ProgramBenefitsBlock-module--blue-checkbox--33kMZ";
export var subBulletPointContainer = "ProgramBenefitsBlock-module--sub-bullet-point-container--3vu53";
export var subBulletPointUL = "ProgramBenefitsBlock-module--sub-bullet-point-UL--2Vfxj";
export var subBulletPoint = "ProgramBenefitsBlock-module--sub-bullet-point--2zyWC";
export var dot = "ProgramBenefitsBlock-module--dot--3mgWJ";
export var disclaimer = "ProgramBenefitsBlock-module--disclaimer---byB5";
export var ctaButton = "ProgramBenefitsBlock-module--cta-button--1ytrL";
export var howItWorksBackgroundBlock = "ProgramBenefitsBlock-module--how-it-works-background-block--2xWrw";
export var howItWorks = "ProgramBenefitsBlock-module--how-it-works--1tsCN";
export var mobileCtaButton = "ProgramBenefitsBlock-module--mobile-cta-button--8-jeB";
export var numberedBullet = "ProgramBenefitsBlock-module--numbered-bullet--2yeJg";