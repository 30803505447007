// extracted by mini-css-extract-plugin
export var root = "HeroBlock-module--root--295Dw";
export var heroImage = "HeroBlock-module--hero-image--1DtVw";
export var desktopView = "HeroBlock-module--desktop-view--3OtFH";
export var mobileView = "HeroBlock-module--mobile-view--3LP_E";
export var container = "HeroBlock-module--container--11VfN";
export var dppContainer = "HeroBlock-module--dpp-container--2zbY-";
export var extraTall = "HeroBlock-module--extra-tall--3iFZ6";
export var leftRightContainer = "HeroBlock-module--left-right-container--18ju2";
export var dppLeftRightContainer = "HeroBlock-module--dpp-left-right-container--3lxnb";
export var leftSide = "HeroBlock-module--left-side--sFDCq";
export var rightSide = "HeroBlock-module--right-side--GiyNe";
export var contactInformation = "HeroBlock-module--contact-information--3MJ6x";
export var title = "HeroBlock-module--title--25Fzf";
export var contentContainer = "HeroBlock-module--content-container--BjeR3";
export var content = "HeroBlock-module--content--HB4z2";
export var bottomRow = "HeroBlock-module--bottom-row--1OxVf";
export var leftContainer = "HeroBlock-module--left-container--3G-t_";
export var costBadge = "HeroBlock-module--cost-badge--NtrPz";
export var bottomLeftNoCostBadge = "HeroBlock-module--bottom-left-no-cost-badge--31AWv";
export var badgePrice = "HeroBlock-module--badge-price--3dB5O";
export var badgeRecurance = "HeroBlock-module--badge-recurance--Nm3k6";
export var large = "HeroBlock-module--large--1i11N";
export var medium = "HeroBlock-module--medium--dsX3-";
export var leftBottom = "HeroBlock-module--left-bottom--1fA4j";
export var bottom = "HeroBlock-module--bottom--Uhx6m";
export var rightBottom = "HeroBlock-module--right-bottom--3iYjr";
export var riseUp = "HeroBlock-module--rise-up--3tRWc";
export var mobileHeroBlock = "HeroBlock-module--mobile-hero-block--26oGj";
export var marginDiv = "HeroBlock-module--margin-div--O_iWL";
export var mobileHeroImage = "HeroBlock-module--mobile-hero-image--38bfX";
export var mobileCtaContainer = "HeroBlock-module--mobile-cta-container--1_AUM";
export var largeText = "HeroBlock-module--large-text--3Iwe_";
export var genStyle = "HeroBlock-module--gen-style--1X6EU";
export var dppHeroImage = "HeroBlock-module--dpp-hero-image--39VT3";
export var medicareAdvantageContainer = "HeroBlock-module--medicare-advantage-container--lsOPA";
export var wphfHeroContainer = "HeroBlock-module--wphf-hero-container--14naN";
export var contentBlock = "HeroBlock-module--content-block--1Rh2A";
export var contentItem = "HeroBlock-module--content-item--2Zyt5";
export var contact = "HeroBlock-module--contact--Zrh6d";
export var numberButton = "HeroBlock-module--number-button--3kul9";
export var wphfBadgeItem = "HeroBlock-module--wphf-badge-item--qFam6";
export var mystrengthHeroContainer = "HeroBlock-module--mystrength-hero-container--NxSrz";
export var mystrengthBadgeItem = "HeroBlock-module--mystrength-badge-item--qtUeh";
export var livongoComStyle = "HeroBlock-module--livongo-com-style--3T94D";
export var ctaButton = "HeroBlock-module--cta-button--FBFRz";