// extracted by mini-css-extract-plugin
export var root = "CTABlock-module--root--3WzFs";
export var left = "CTABlock-module--left--1PCw7";
export var content = "CTABlock-module--content--Agvhe";
export var right = "CTABlock-module--right--1Der5";
export var hasMobileCtaButton = "CTABlock-module--has-mobile-cta-button--3tp8N";
export var title = "CTABlock-module--title--3W89x";
export var subtitle = "CTABlock-module--subtitle--36oB2";
export var dppRoot = "CTABlock-module--dpp-root--1xU9X";
export var contactInformation = "CTABlock-module--contact-information--1ugrp";
export var smallerStyle = "CTABlock-module--smaller-style--QTtMZ";
export var contactInfoBlueText = "CTABlock-module--contact-info-blue-text--1M7BZ";