// extracted by mini-css-extract-plugin
export var footer = "Footer-module--footer--1LEZJ";
export var ctaButtonShowing = "Footer-module--cta-button-showing--2lh0Z";
export var container = "Footer-module--container--3XNmD";
export var flexContainer = "Footer-module--flex-container--2pIEy";
export var leftContainer = "Footer-module--left-container--gwp6O";
export var rightContainer = "Footer-module--right-container--3i8vq";
export var disclaimer = "Footer-module--disclaimer--O0XGU";
export var link = "Footer-module--link--2uG0B";
export var memberCommunicationPolicyLink = "Footer-module--member-communication-policy-link--2cQXg";
export var modalGrayOut = "Footer-module--modal-gray-out--2kC_8";
export var open = "Footer-module--open--2zE7l";
export var memberCommunicationPolicy = "Footer-module--member-communication-policy--2KctL";
export var title = "Footer-module--title--3M9Gj";
export var memberCommunicationPolicyBody = "Footer-module--member-communication-policy-body--1t5Ls";
export var closeButton = "Footer-module--close-button--2oeew";
export var bhFooter = "Footer-module--bh-footer--1ZWq2";
export var dppFooter = "Footer-module--dpp-footer--ASmO7";
export var dppImage = "Footer-module--dpp-image--k7wda";
export var description = "Footer-module--description--16wm-";
export var dppBottomContainer = "Footer-module--dpp-bottom-container--2i19F";
export var livongoComStyle = "Footer-module--livongo-com-style--3qXh8";
export var rainbowBorder = "Footer-module--rainbow-border--2fFrA";
export var linkContainer = "Footer-module--link-container--7hxPx";
export var linkColumn = "Footer-module--link-column--3v1Z6";
export var subtitle = "Footer-module--subtitle--22y5n";
export var footerColumnLink = "Footer-module--footer-column-link--Ysl1p";
export var socialLinkContainer = "Footer-module--social-link-container--1j9d7";
export var row = "Footer-module--row--3DUrM";
export var rightSpacer = "Footer-module--right-spacer--3cYxJ";
export var iconSpacer = "Footer-module--icon-spacer--hSyar";
export var socialMediaRow = "Footer-module--social-media-row--3UqLW";
export var flexLinkContainer = "Footer-module--flex-link-container--26UG5";