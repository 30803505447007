// extracted by mini-css-extract-plugin
export var root = "AppBlock-module--root--2Lw-2";
export var genStyle = "AppBlock-module--gen-style--1QmJv";
export var context = "AppBlock-module--context--2cFzb";
export var mystrengthStyle = "AppBlock-module--mystrength-style--3Yygz";
export var title = "AppBlock-module--title--3WvnZ";
export var subtitle = "AppBlock-module--subtitle--ZEGfP";
export var content = "AppBlock-module--content--21ziV";
export var btnItems = "AppBlock-module--btn-items--dl0qD";
export var ctaButton = "AppBlock-module--cta-button--11JlA";
export var playStore = "AppBlock-module--play-store--2wHRi";
export var appStore = "AppBlock-module--app-store--1VtUe";