// extracted by mini-css-extract-plugin
export var root = "WhatYouGetBlock-module--root--3AxaM";
export var title = "WhatYouGetBlock-module--title--1wu_o";
export var subtitle = "WhatYouGetBlock-module--subtitle--3amBM";
export var disclaimer = "WhatYouGetBlock-module--disclaimer--LXM8l";
export var listItem = "WhatYouGetBlock-module--list-item--3XHOu";
export var content = "WhatYouGetBlock-module--content--28-SP";
export var listItems = "WhatYouGetBlock-module--list-items--5UlX3";
export var BLUE = "WhatYouGetBlock-module--BLUE--GzmVr";
export var GREEN = "WhatYouGetBlock-module--GREEN--1jqZX";
export var left = "WhatYouGetBlock-module--left--ziRmW";
export var right = "WhatYouGetBlock-module--right--29m9M";
export var costBadge = "WhatYouGetBlock-module--cost-badge--2s1mS";
export var badgePrice = "WhatYouGetBlock-module--badge-price--HbrPS";
export var badgeRecurance = "WhatYouGetBlock-module--badge-recurance--17Qkj";
export var ctaButton = "WhatYouGetBlock-module--cta-button--WwPMi";
export var dppContainer = "WhatYouGetBlock-module--dpp-container--zCIze";
export var dppContact = "WhatYouGetBlock-module--dpp-contact--27X7d";
export var dppCtaBtn = "WhatYouGetBlock-module--dpp-cta-btn--3iq0v";
export var listItemsContainer = "WhatYouGetBlock-module--list-items-container--WIq15";
export var imageOnLeft = "WhatYouGetBlock-module--image-on-left--TiXZK";
export var titlesContainer = "WhatYouGetBlock-module--titles-container--188sz";