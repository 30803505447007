// extracted by mini-css-extract-plugin
export var root = "TestimonialsBlock-module--root--zHeFM";
export var title = "TestimonialsBlock-module--title--1Gf0j";
export var subtitle = "TestimonialsBlock-module--subtitle--3NIxn";
export var content = "TestimonialsBlock-module--content--2cLkQ";
export var backgroundImage = "TestimonialsBlock-module--background-image--3IKU8";
export var testimonialsDisclaimer = "TestimonialsBlock-module--testimonials-disclaimer--13uhu";
export var testimonialCards = "TestimonialsBlock-module--testimonial-cards--1jjg6";
export var largerTextStyle = "TestimonialsBlock-module--larger-text-style--555X9";
export var cardItem = "TestimonialsBlock-module--card-item--3IgVV";
export var testimonial = "TestimonialsBlock-module--testimonial--3XewA";
export var offset = "TestimonialsBlock-module--offset--3KJ3m";
export var last3Small = "TestimonialsBlock-module--last-3-small--3toiV";
export var last3Large = "TestimonialsBlock-module--last-3-large--3gzKM";
export var memberName = "TestimonialsBlock-module--member-name--3mVFN";
export var memberDescription = "TestimonialsBlock-module--member-description--3udfJ";
export var memberImage = "TestimonialsBlock-module--member-image--3v-Wx";
export var dppStyle = "TestimonialsBlock-module--dpp-style--F5sAm";
export var dppMemberImage = "TestimonialsBlock-module--dpp-member-image--Gk_cQ";
export var wphfContainer = "TestimonialsBlock-module--wphf-container--3Mlqh";
export var memberDetails = "TestimonialsBlock-module--member-details--1gYaq";
export var mystrength = "TestimonialsBlock-module--mystrength--zujrh";
export var livongoComStyle = "TestimonialsBlock-module--livongo-com-style--2yJKZ";