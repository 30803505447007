// extracted by mini-css-extract-plugin
export var root = "CarouselBlock-module--root--1wuqj";
export var container = "CarouselBlock-module--container--22twG";
export var title = "CarouselBlock-module--title--2Glro";
export var subtitle = "CarouselBlock-module--subtitle--2VJFy";
export var content = "CarouselBlock-module--content--2u8gl";
export var leftContainer = "CarouselBlock-module--left-container--4stOr";
export var rightContainer = "CarouselBlock-module--right-container--1xwr9";
export var carouselImage = "CarouselBlock-module--carousel-image--3i5hf";
export var slider = "CarouselBlock-module--slider--1IrDB";
export var flexContainer = "CarouselBlock-module--flex-container--2X6HP";
export var imageContainer = "CarouselBlock-module--image-container--37w9y";
export var carouselCard = "CarouselBlock-module--carousel-card--2O8Oe";
export var verticalDots = "CarouselBlock-module--vertical-dots--3KWNG";
export var slickActive = "CarouselBlock-module--slick-active--fZ9CX";
export var mystrengthStyle = "CarouselBlock-module--mystrength-style--QC9dl";