//use this func. only if the URL's environment is included in the .env.development and .env.production
export const convertEnvironmentLivongoLink = link => {
    const isDevelopment = process.env.NODE_ENV === 'development';
    const isWindowDefined = typeof window !== 'undefined';
    const queryString = isWindowDefined ? window.location.search : '';
    const hostname = isWindowDefined ? window.location.hostname : '';
    let environment = '';

    if (isDevelopment) {
        environment = 'integration';
    }

    if (isWindowDefined) {
        if (
            hostname.includes('.integration.livongo.com') ||
            hostname.includes('.local.livongo.com')
        ) {
            environment = 'integration';
        } else if (hostname.includes('.preprod.livongo.com')) {
            environment = 'preprod';
        }
    }

    /* 
        Handle specific cases for 'my' or 'signup' in the link when on the tdoctest domain:
        https://signup.livongo.com redirects to ---> https://signup-ccmpreprod.tdoctest.com
        https://my.livongo.com/login redirects to ---> https://my-ccmpreprod.tdoctest.com 
    */
    if (
        (link.includes('my.') || link.includes('signup.')) &&
        hostname.includes('.tdoctest.')
    ) {
        const subdomain = link.split('.')[0];
        link = `${subdomain}-${hostname.split('-')[1]}${queryString}`;
    }

    if (
        environment !== '' &&
        link.includes('.livongo.com') &&
        !link.includes(`.${environment}.livongo.com`)
    ) {
        link = link.replace('.livongo.com', `.${environment}.livongo.com`);
    }

    return link;
};

export const getCloudinaryImageUrl = cloudinaryImage => {
    if (
        cloudinaryImage?.image &&
        Array.isArray(cloudinaryImage.image) &&
        cloudinaryImage.image.length > 0 &&
        cloudinaryImage.image[0].url
    ) {
        let url = cloudinaryImage.image[0].url;
        url = url.replace('http://', 'https://');

        return url;
    }
    return null;
};

export const getCloudinaryVideoUrl = cloudinaryVideo => {
    if (
        cloudinaryVideo?.video &&
        Array.isArray(cloudinaryVideo.video) &&
        cloudinaryVideo.video.length > 0 &&
        cloudinaryVideo.video[0].url
    ) {
        let url = cloudinaryVideo.video[0].url;
        url = url.replace('http://', 'https://');

        return url;
    }

    return null;
};

export const getCloudinaryObject = cloudinaryFile => {
    if (
        cloudinaryFile?.video &&
        Array.isArray(cloudinaryFile.video) &&
        cloudinaryFile.video.length > 0 &&
        cloudinaryFile.video[0].url
    ) {
        let url = cloudinaryFile.video[0].url.replace('http://', 'https://');
        const {width, height} = cloudinaryFile.video[0];
        return {
            url,
            width,
            height,
            name: cloudinaryFile.name,
        };
    }

    if (
        cloudinaryFile?.image &&
        Array.isArray(cloudinaryFile.image) &&
        cloudinaryFile.image.length > 0 &&
        cloudinaryFile.image[0].url
    ) {
        let url = cloudinaryFile.image[0].url.replace('http://', 'https://');
        const {width, height} = cloudinaryFile.image[0];
        return {
            url,
            width,
            height,
            name: cloudinaryFile.name,
        };
    }

    return null;
};

export const removeNullFromProps = props => {
    let newProps = {};

    for (var key in props) {
        if (props.hasOwnProperty(key) && props[key] !== null) {
            newProps[key] = props[key];
        }
    }

    return newProps;
};

export const convertMyStrengthEnvironment = url => {
    let environment = process.env.NODE_ENV;

    if (environment === 'development') {
        return 'https://web-ui-bhdts.mystrength.livongo.com/login';
    } else if (environment === 'preprod') {
        return 'https://web-ui-stage.mystrength.livongo.com/login';
    } else {
        return url;
    }
};
