import qs from 'qs';

import MixpanelUtils from '@livongo/utils/mixpanel';

const PREFIX = 'LP 5';
// Mixpanel Events
const LANDING_PAGE_LOAD = `${PREFIX} - Landing page load`;
const CTA_CLICKED = `${PREFIX} - CTA button click`;
const SIGN_IN_CLICKED = `${PREFIX} - Sign in click`;
const LIVE_CHAT_ClICKED = `${PREFIX} - Live chat click`;
const PHONE_NUMBER_CLICKED = `${PREFIX} - Phone number click`;
const TRANSLATION_CLICKED = `${PREFIX} - Translation click`;
const VIDEO_VIEWED = `${PREFIX} - Video viewed`;
const UTM_KEYWORDS = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_term',
];

export const landingPageLoad = () => {
    const properties = getBaseProperties();

    MixpanelUtils.track({
        event: LANDING_PAGE_LOAD,
        properties,
    });
};

export const ctaClicked = () => {
    const properties = getBaseProperties();

    MixpanelUtils.track({
        event: CTA_CLICKED,
        properties,
    });
};

export const signInClicked = () => {
    const properties = getBaseProperties();

    MixpanelUtils.track({
        event: SIGN_IN_CLICKED,
        properties,
    });
};

export const liveChatClicked = () => {
    const properties = getBaseProperties();

    MixpanelUtils.track({
        event: LIVE_CHAT_ClICKED,
        properties,
    });
};

export const phoneNumberClicked = () => {
    const properties = getBaseProperties();

    MixpanelUtils.track({
        event: PHONE_NUMBER_CLICKED,
        properties,
    });
};

export const translationClicked = () => {
    const properties = getBaseProperties();

    MixpanelUtils.track({
        event: TRANSLATION_CLICKED,
        properties,
    });
};

export const videoViewed = () => {
    const properties = getBaseProperties();

    MixpanelUtils.track({
        event: VIDEO_VIEWED,
        properties,
    });
};

const getBaseProperties = () => {
    let properties = {};

    if (typeof window !== 'undefined') {
        const pathname = window?.location?.pathname;
        const [_, ccid, customization] = pathname.split('/');

        properties['Location'] = PREFIX;
        properties['CCID'] = ccid;
        properties['Customization'] = customization;

        var searchParamObj = qs.parse(window.location.search, {
            ignoreQueryPrefix: true,
        });

        let regCode =
            searchParamObj['reg_code'] ||
            searchParamObj['regCode'] ||
            searchParamObj['regcode'];

        if (regCode) {
            properties['Reg Code'] = regCode;
        }

        let experimentID = searchParamObj['vwo'];

        if (experimentID) {
            properties['Experiment ID'] = experimentID;
        }

        UTM_KEYWORDS.forEach(kw => {
            let value = searchParamObj[kw];
            if (value?.length) {
                properties[kw + ' [current]'] = value;
            }
        });
    }

    return properties;
};

export const setSuperAndPeopleProperties = () => {
    let properties = {};
    let otherProperties = {};
    let firstTouchProperties = {};

    if (typeof window !== 'undefined') {
        var searchParamObj = qs.parse(window.location.search, {
            ignoreQueryPrefix: true,
        });

        var keys = [
            {
                keywordQueryParamName: 'vwo',
                mixpanelSuperPropertyName: 'Experiment ID',
            },
            {
                keywordQueryParamName: 'campaign_id',
                mixpanelSuperPropertyName: 'Campaign ID',
            },
            {
                keywordQueryParamName: 'adset_id',
                mixpanelSuperPropertyName: 'Adset ID',
            },
            {
                keywordQueryParamName: 'ad_id',
                mixpanelSuperPropertyName: 'Ad ID',
            },
            {
                keywordQueryParamName: 'keyword',
                mixpanelSuperPropertyName: 'Keyword',
            },
            {
                keywordQueryParamName: 'metadata',
                mixpanelSuperPropertyName: 'Metadata',
            },
        ];

        let trackingID = searchParamObj['tracking_id'];
        if (trackingID) {
            properties['Livongo Tracking ID'] = trackingID;
        }

        keys.forEach(key => {
            let trackingValue = searchParamObj[key.keywordQueryParamName];

            if (trackingValue) {
                otherProperties[key.mixpanelSuperPropertyName] = trackingValue;
            }
        });

        UTM_KEYWORDS.forEach(kw => {
            let value = searchParamObj[kw];
            if (value?.length) {
                firstTouchProperties[kw + ' [first touch]'] = value;
                properties[kw + ' [last touch]'] = value;
            }
        });

        MixpanelUtils.identify();
        MixpanelUtils.setPeoplePropsOnce(firstTouchProperties);
        MixpanelUtils.setPeopleProps(properties);
        MixpanelUtils.setSuperPropsOnce(firstTouchProperties);
        MixpanelUtils.setSuperProps(properties);
        MixpanelUtils.setSuperProps(otherProperties);
    }
};
