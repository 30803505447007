// extracted by mini-css-extract-plugin
export var root = "FAQBlock-module--root--2poF7";
export var title = "FAQBlock-module--title--2pgMi";
export var subtitle = "FAQBlock-module--subtitle--3qY_N";
export var content = "FAQBlock-module--content--21jXp";
export var leftRightContainer = "FAQBlock-module--left-right-container--Hf51V";
export var openChat = "FAQBlock-module--open-chat--2JFAC";
export var singleColumn = "FAQBlock-module--single-column--Lygh9";
export var questionsSection = "FAQBlock-module--questions-section--3ZGBh";
export var questionSection = "FAQBlock-module--question-section--RdFDF";
export var question = "FAQBlock-module--question--3nH76";
export var glypicon = "FAQBlock-module--glypicon--eFMUY";
export var plus = "FAQBlock-module--plus--olFO5";
export var answer = "FAQBlock-module--answer--3xTBe";
export var minus = "FAQBlock-module--minus--AdGCq";
export var show = "FAQBlock-module--show--3SGrS";
export var dppContainer = "FAQBlock-module--dpp-container--zWoxg";
export var livongoComBackgroundBlock = "FAQBlock-module--livongo-com-background-block--2kKj8";
export var livongoComStyle = "FAQBlock-module--livongo-com-style--2TKv0";
export var mystrengthStyle = "FAQBlock-module--mystrength-style--1uH79";
export var medicareAdvantageStyle = "FAQBlock-module--medicare-advantage-style--2Z6di";
export var additionalQuestions = "FAQBlock-module--additional-questions--3pBBY";
export var numberButton = "FAQBlock-module--number-button--1Mq7T";