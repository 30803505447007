// extracted by mini-css-extract-plugin
export var root = "RatingBlock-module--root--27I97";
export var backgroundBlock = "RatingBlock-module--background-block--2WzUP";
export var title = "RatingBlock-module--title--q62SP";
export var appsContainer = "RatingBlock-module--apps-container--16Alq";
export var row = "RatingBlock-module--row--2DIVJ";
export var socialLink = "RatingBlock-module--social-link--1GcUZ";
export var appStoreContainer = "RatingBlock-module--app-store-container--2GuOT";
export var rating = "RatingBlock-module--rating--8flRI";
export var starBox = "RatingBlock-module--star-box--2Lx-7";
export var stars = "RatingBlock-module--stars--LysMG";
export var reviewCount = "RatingBlock-module--review-count--Ztn7Z";
export var logoBox = "RatingBlock-module--logo-box--3jcoQ";
export var appleLogo = "RatingBlock-module--apple-logo--HOCUF";
export var appStoreText = "RatingBlock-module--app-store-text--1lui1";
export var facebookContainer = "RatingBlock-module--facebook-container--o3onc";
export var thumbsUp = "RatingBlock-module--thumbs-up--1B8gf";
export var facebookLogo = "RatingBlock-module--facebook-logo--1vYGU";