// extracted by mini-css-extract-plugin
export var root = "FocusBlock-module--root--1d6lm";
export var title = "FocusBlock-module--title--3VFde";
export var subtitle = "FocusBlock-module--subtitle--2lciJ";
export var content = "FocusBlock-module--content--30bUn";
export var ctaButton = "FocusBlock-module--cta-button--2ZTGv";
export var pointsContainer = "FocusBlock-module--points-container--3H8bP";
export var point = "FocusBlock-module--point--14DLU";
export var mystrengthFocusBlockContainer = "FocusBlock-module--mystrength-focus-block-container--2ZqWX";
export var titleItem = "FocusBlock-module--title-item--1948w";
export var contentItem = "FocusBlock-module--content-item--3Nxzh";
export var pointsItem = "FocusBlock-module--points-item--3BUYA";
export var ctaBtn = "FocusBlock-module--cta-btn--3okf-";